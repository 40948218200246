export const options1 = [
  "北海道","青森県","岩手県","宮城県","秋田県","山形県","福島県",
  "茨城県","栃木県","群馬県","埼玉県","千葉県","東京都","神奈川県",
  "新潟県","富山県","石川県","福井県","山梨県","長野県","岐阜県",
  "静岡県","愛知県","三重県","滋賀県","京都府","大阪府","兵庫県",
  "奈良県","和歌山県","鳥取県","島根県","岡山県","広島県","山口県",
  "徳島県","香川県","愛媛県","高知県","福岡県","佐賀県","長崎県",
  "熊本県","大分県","宮崎県","鹿児島県","沖縄県"
]

export const options2 = [
  "はい",
  "いいえ　将来的に空き家を所有する可能性がある",
  "いいえ　家族、親族が所有している。または、所有する可能性がある。",
  "いいえ　所有する可能性はない"
]

export const options3 = [
  "相続した",
  "自分が新築した、新築を購入した",
  "自分が中古住宅を購入した",
  "譲渡された",
  "その他"
]

export const options4 = [
  "住んでいた人が死亡したため空き家となった",
  "住んでいた人の入院、施設入所等により空き家になった",
  "転勤等、遠方に転居することになった",
  "親や子の世帯と一緒に住むことになった",
  "その他"
]

export const options5 = ["1年未満", "1年以上2年未満", "2年以上5年未満", "5年以上10年未満", "10年以上", "不明"]

export const options6 = ["3年未満", "3年以上10年未満", "10年以上20年未満", "20年以上30年未満", "30年以上40年未満", "40年以上", "不明"]

export const options7 = [
  "物置・倉庫として利用している",
  "仕事場・作業場として利用している",
  "季節限定、正月や盆休みなど、時々利用している",
  "利用していない",
  "その他",
]

export const options8 = ["週に1回以上", "2週に1回程度", "月に1回程度", "6ヶ月に1回程度", "年に1回程度", "ほとんどしていない", "1度もしたことない", "わからない"]

export const options9 = ["所有者", "所有者の家族・親族", "近所の人", "知人・友人", "専門業者", "警備会社", "誰も管理していない", "その他"]

export const options10 = [
  "売却したい、または売却しても良い",
  "賃貸したい、または賃貸しても良い",
  "数年以内に自分または家族等が住む（新築・改築含む）",
  "特に利用の予定はなく、現状のまま維持したい",
  "家族（子や孫など）に任せる",
  "その他"
]

export const age = ["10代以下", "20代", "30代", "40代", "50代", "60代", "70代以上"]

export const sex = ["男性", "女性"]

export const occupation = ["会社員・役員", "自営業", "公務員", "学生", "専業主婦", "パート・アルバイト", "無職"]